import { useState, useEffect } from 'react';
import calendar from "../../assets/calendar.png";
import coffee from "../../assets/coffee.png";
import tv from "../../assets/tv.png";
import palm from "../../assets/palm.png";

const CalculatorComponent = () => {
    const [role, setRole] = useState('');
    const [salary, setSalary] = useState(0);
    const [fluency, setFluency] = useState('');
    const [continent, setContinent] = useState('');
    const [savings, setSavings] = useState(null);
    const [numberOfHires, setNumberOfHires] = useState(1);
    const [validationMessage, setValidationMessage] = useState('');

    const roleSalaries = {
        "Sales Representative": 1500,
        "Customer Service Representative": 1200,
        "Technical Support Specialist": 1400,
        "Inside Sales Associate": 1500,
        "Lead Generation Specialist": 1300,
        "Account Manager": 1200,
        "Customer Success Manager": 1200,
    };

    const fluencyAdjustments = {
        "Beginner": -100,
        "Intermediate": 0,
        "Fluent": 100,
    };

    const continentAdjustments = {
        "Americas (GMT -8 to -3)": 100,
        "Europe/Africa (GMT 0 to +3)": 0,
        "Middle East/West Asia (GMT +3 to +5.5)": -100,
        "East Asia/Pacific (GMT +7 to +10)": -100,
        "Southeast Asia (GMT +5.5 to +8)": -200,
    };

    useEffect(() => {
        if (role && salary > 0 && fluency && continent && numberOfHires > 0) {
            const totalCost = (roleSalaries[role] || 0) + (fluencyAdjustments[fluency] || 0) + (continentAdjustments[continent] || 0);
            const calculatedSavings = (salary - totalCost) * numberOfHires;
            setSavings(calculatedSavings > 0 ? calculatedSavings : 0);
            setValidationMessage(''); // Clear validation message if all fields are filled
        } else if (savings !== null) {
            setSavings(null);
            setValidationMessage('Please fill out all fields to calculate savings.');
        }
    }, [role, salary, fluency, continent, numberOfHires]);

    return (
        <div className='py-44'>
            <h2 className="max-w-lg mb-6 font-sans text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-5xl md:mx-auto">
              <span className="relative inline-block">
                Savings 
                <span className="relative text-rockethire-orange"> Calculator </span>
              </span>{' '}
            </h2> 

            <p className="text-base text-gray-700 md:text-lg max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              With this <span className="font-bold text-black">calculator</span>, you will be able to see how much hiring off-shore will save you.
              All this while maintaining quality of your internal team and being fully compliant with local laws.       
            </p>
            <div className="mx-auto max-w-4xl p-8 bg-white rounded-2xl shadow-md border">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="flex-1">
                        <div className="flex-1 space-y-6">
                            <h1 className='text-3xl font-bold'>Calculate how much a <span className='navbar-logo-title text-4xl'>ROCKET<span className='text-rockethire-orange'>HIRE</span></span> would save you.</h1>
                            <div className="mt-4">
                                <h1 className='text-xl pb-4'>Tell us more about the role you are looking to hire for your company:</h1>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="">What role would you like to hire?:</option>
                                    <option value="Sales Representative">Sales Representative</option>
                                    <option value="Customer Service Representative">Customer Service Representative</option>
                                    <option value="Technical Support Specialist">Technical Support Specialist</option>
                                    <option value="Inside Sales Associate">Inside Sales Associate</option>
                                    <option value="Lead Generation Specialist">Lead Generation Specialist</option>
                                    <option value="Account Manager">Account Manager</option>
                                    <option value="Customer Success Manager">Customer Success Manager</option>
                                </select>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600 mt-6 mb-6"
                                    value={continent}
                                    onChange={(e) => setContinent(e.target.value)}
                                >
                                    <option value="">What time zone would you like your candidate to be from?:</option>
                                    <option value="Americas (GMT -8 to -3)">Americas</option>
                                    <option value="Europe/Africa (GMT 0 to +3)">Europe/Africa</option>
                                    <option value="Middle East/West Asia (GMT +3 to +5.5)">Middle East/West Asia</option>
                                    <option value="East Asia/Pacific (GMT +7 to +10)">East Asia/Pacific</option>
                                    <option value="Southeast Asia (GMT +5.5 to +8)">Southeast Asia</option>
                                </select>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600 mt-6 mb-6"
                                    value={fluency}
                                    onChange={(e) => setFluency(e.target.value)}
                                >
                                    <option value="">Select the level of English preferred:</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Professional">Intermediate</option>
                                    <option value="Fluent">Fluent</option>
                                </select>
                                
                                <div className="mt-6 mb-6 text-xl">
                                    <label htmlFor="numberOfHires" className="block text-black">
                                        Number of Hires you'd like to make:
                                    </label>
                                    <input
                                        type="number"
                                        id="numberOfHires"
                                        className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600 mt-6 mb-6 text-center"
                                        value={numberOfHires}
                                        onChange={(e) => setNumberOfHires(Number(e.target.value))}
                                        min="1"
                                    />
                                </div>

                                <div className="text-center p-4 bg-white rounded-xl border shadow-md">
                                    <div>
                                        <label htmlFor="price" className="block leading-6 text-black text-l">
                                            How much would this hire cost you locally (monthly)?
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <span className="text-gray-500 sm:text-sm">$</span>
                                            </div>
                                            <input
                                                type="number"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                                value={salary}
                                                onChange={(e) => setSalary(Number(e.target.value))}
                                                min="2000"
                                                max="10000"
                                                step="100"
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label htmlFor="currency" className="sr-only">
                                                    Currency
                                                </label>
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                    <option>USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type="range"
                                        className="w-1/2 h-2 bg-gray-300 rounded-lg cursor-pointer mx-auto"
                                        min="2000"
                                        max="10000"
                                        step="100"
                                        value={salary}
                                        onChange={(e) => setSalary(Number(e.target.value))}
                                    />
                                    <div className="flex justify-between text-xs px-2">
                                        <span className='pl-24'>$2000</span>
                                        <span className='pr-24'>$10000</span>
                                    </div>
                                </div>
                            </div>

                            {/* Display Validation Message */}
                            {validationMessage && (
                                <p className="text-red-500 text-center mt-4 font-medium">{validationMessage}</p>
                            )}

                            <h2 className="text-3xl font-bold text-gray-900 mt-6">
                                Every month, you could be saving <span className="text-rockethire-orange">${savings}</span> with our services.
                            </h2>
                            <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 sm:mx-auto">
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={calendar} alt="calendar" />
                                    <h5 className="font-semibold">Every year, that would be</h5>
                                    <span className='text-rockethire-orange font-bold'>${savings ? (savings * 12).toLocaleString() : 0}</span> <span className='font-semibold'>in salaries saved.</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={coffee} alt="coffee" />
                                    <h5 className="font-semibold">Which translates to about</h5>
                                    <span className='text-rockethire-orange font-bold'>{savings ? ((savings * 12) / 2).toLocaleString() : 0}</span> <span className='font-semibold'>cups of coffee every year</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={tv} alt="tv" />
                                    <h5 className="font-semibold">Or around</h5>
                                    <span className='text-rockethire-orange font-bold'>{savings ? Math.trunc((savings * 12) / 7).toLocaleString() : 0}</span> <span className='font-semibold'>Netflix subscriptions</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                        <img className='h-8 mx-auto' src={palm}/>
                                        <h5 className="font-semibold">It could also be </h5>
                                        <span className='text-rockethire-orange font-bold'> ${savings ? (savings * 12).toLocaleString() : 0}</span><span className='font-semibold'> in downpayment for that beach house...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculatorComponent;