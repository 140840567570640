import React from 'react'
import customersupport from '../../assets/gif/customersupport.gif'
import handshake from '../../assets/gif/handshake.gif'
import technology from '../../assets/gif/technology.gif'
import spacebackground from "../../assets/spacebackground.jpg";

const RolesWeHire = () => {
  return (
    <div>
      
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">


          </span>{''}
          Roles We Help You <span className="text-rockethire-orange">Hire</span>
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
        With over 7 years of experience, we’ve successfully hired more than 600 top-tier professionals
        across Customer Support, Sales, and Technology. Let us help you find the perfect fit to grow 
        your team.

        </p>
      </div>
      <div className="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-xl lg:grid-cols-3 sm:mx-auto">
        <div className="flex flex-col justify-between p-5 bg-white border-2 rounded shadow-xl border-rockethire-orange">
          <div className="mb-6">
            <div className="flex items-center justify-between pb-6 mb-6 border-b border-rockethire-orange">
              <div>
                <p className="text-sm font-bold tracking-wider uppercase">
                  
                </p>
                <p className="text-2xl font-extrabold">Customer Support</p>
              </div>
              <div className="flex items-center justify-center w-20\\ h-24 rounded-full bg-white">
                <img src={customersupport} alt='customersupport'></img>
              </div>
            </div>
            <div>
              <p className="mb-2 font-bold tracking-wide">Positions:</p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">
                    Customer Support Specialist
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Technical Support Representative</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Customer Success Manager</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Help Desk Coordinator</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Operations Support</p>
                </li>
              </ul>
            </div>
          </div>
          <div>

          <a
            href="https://calendly.com/rockethire-coffee/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
          >
            Delivery in 1-2 Weeks
          </a>

            <p className="text-sm text-gray-600">
            Our specialized hires in Customer Support will offer excellent service at every touchpoint.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 bg-white border-2 rounded shadow-xl border-rockethire-orange">
          <div className="mb-6">
            <div className="flex items-center justify-between pb-6 mb-6 border-b border-rockethire-orange">
              <div>
                <p className="text-sm font-bold tracking-wider uppercase">
                  
                </p>
                <p className="text-2xl font-extrabold">Sales Roles</p>
              </div>
              <div className="flex items-center justify-center w-24 h-24 rounded-full bg-white">
              <img src={handshake} alt='handshake'></img>
              </div>
            </div>
            <div>
              <p className="mb-2 font-bold tracking-wide">Positions:</p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Sales Development Representative</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">
                  Account Executive
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Business Development Manager
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Sales Operations Analyst</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Sales Enablement Specialist</p>
                </li>
              </ul>
            </div>
          </div>
          <div>

          <a
            href="https://calendly.com/rockethire-coffee/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
          >
            Delivery in 1-2 Weeks
          </a>

            <p className="text-sm text-gray-600">
            Sales talent with the experience to build relationships and close deals in any market.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 bg-white border-2 rounded shadow-xl border-rockethire-orange">
          <div className="mb-6">
            <div className="flex items-center justify-between pb-6 mb-6 border-b border-rockethire-orange">
              <div>
                <p className="text-sm font-bold tracking-wider uppercase">
                  
                </p>
                <p className="text-2xl font-extrabold">Technology Roles</p>
              </div>
              <div className="flex items-center justify-center w-24 h-24 rounded-full bg-white">
              <img src={technology} alt='technology'></img>
              </div>
            </div>
            <div>
              <p className="mb-2 font-bold tracking-wide">Positions:</p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Software Engineer</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">
                  Full Stack Developer
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">DevOps Engineer</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Data Engineer</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      className="w-4 h-4 text-black"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLidth="2"
                    >
                      <polyline
                        fill="none"
                        stroke="currentColor"
                        points="6,12 10,16 18,8"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        fill="none"
                        r="11"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                  <p className="font-medium text-gray-800">Cloud Engineer</p>
                </li>
              </ul>
            </div>
          </div>
          <div>

          <a
            href="https://calendly.com/rockethire-coffee/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
          >
            Delivery in 1-2 Weeks
          </a>

            <p className="text-sm text-gray-600">
            We connect you with the best technical minds in the business to help you build and innovate.


            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default RolesWeHire
