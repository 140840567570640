import officebanner from "../../assets/officebanner.jpg";
import Spline from "@splinetool/react-spline";
import { Link } from 'react-scroll';

const links = [
  { name: 'Open roles', href: '#' },
  { name: 'Internship program', href: '#' },
  { name: 'Our values', href: '#' },

];
const stats = [
  { name: "We’ve placed over 750 candidates in sales, support, and tech roles for startups.", value: 'Startup Experts' },
  { name: 'Get top global talent in 1-2 weeks, guaranteed. Quick results while ensuring quality.', value: 'Hire in 2 Weeks' },
  { name: 'Save 70% annually by hiring offshore talent. Risk-free - Pay only when you hire - w\ith a 2-month guarantee.', value: '$1800 Flat Fee' },
];

export default function TopBanner() {
  return (
    <div className="relative isolate overflow-hidden bg-[#FFF] py-12 sm:py-12">
        <img
          src={officebanner}
          alt="officebanner"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-[6%]"
          /> 
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        
        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col items-center text-center duration-300 transform bg-[#ffffff56] border-2 rounded p-2 shadow-sm border-rockethire-orange hover:-translate-y-2 hover:shadow-xl">
                <dd className="text-2xl font-bold leading-9 tracking-tight text-rockethire-orange mb-2">{stat.value}</dd>
                <dt className="text-base leading-7 text-black font-medium">{stat.name}</dt>
              </div>
            ))}
          </dl>

        <div className="relative mx-auto max-w-2xl text-center py-12">
          <h2 className="text-2xl font-bold tracking-tight text-black sm:text-3xl">
             Get It <span className="text-rockethire-orange">Right </span> with Rocket<span className="text-rockethire-orange">Hire </span> — Empower Your Startup with the Right Talent, Fast

          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-900">
         {/*   We go straight to the point. We find you Global top talent in 1-2 weeks, you save over 70% annually in salaries, and workers are happy in their local currency.   */}
          </p>
        </div>

        <div className="mx-auto max-w-2xl lg:max-w-none text-center">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:justify-center lg:gap-x-10">
  

          <a
            href="https://calendly.com/rockethire-coffee/30min"
            className="block w-full text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="interactive-button w-full max-w-2xl mx-auto py-4 bg-rockethire-orange rounded border-[#ee595900] hover:border-[#d3b34c] border-[2px] hover:border-[2px] text-lg sm:text-xl lg:text-2xl relative overflow-hidden text-white">
              <span class="default-text block">Let’s Talk Talent – Schedule Your Free Consultation</span>
              <span class="hover-text block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300">Click here for...   Lift off!!</span>
              <div class="fire-effect"></div>
            </button>
          </a>
            
          </div>

        </div>
      </div>
    </div>
  );
}