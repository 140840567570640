import React from "react";
import Spline from "@splinetool/react-spline";
import rungif from "../../assets/gif/rungif.gif";
import chartgif from "../../assets/gif/chartgif.gif";
import trustgif from "../../assets/gif/trustgif.gif";
import geargif from "../../assets/gif/geargif.gif";
import globegif from "../../assets/gif/globegif.gif";



const AboutUs = () => {
  return (
    <div className="z-10 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col items-center lg:flex-row md:mb-10">
        <div className="lg:w-1/2">

          <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg ">
            Why hire with <span className="text-rockethire-orange">us?</span>
          </h2>
        </div>
        <div className="lg:w-1/2">
          <p className="text-base text-black md:text-md">
            At <span className="navbar-logo-title text-2xl">ROCKET<span className="text-rockethire-orange">HIRE</span></span>, we understand that a 
            great fit goes beyond just skills; it's about finding someone who aligns with your company culture 
            and values. That’s why we take a meticulous approach, hand-vetting each candidate and leveraging the 
            latest tools to ensure quality results. Let us bring our expertise to your hiring process and help 
            you find the right talent to enhance your team.
          </p>
        </div>
      </div>

 
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-0">
      <div className="grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="px-12 text-center sm:px-0">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img src={rungif} alt="rungif"/>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            FAST & EFFICIENT
          </h6>
          <div className="mb-2 text-gray-700">
            You will have a team of headhunters working everyday to find your hire
          </div>
        </div>
        <div className="px-12 text-center sm:px-0">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img src={globegif} alt="chartgif"/>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Global Talent
          </h6>
          <div className="mb-2 text-gray-700">
            You don’t get the best talent in your city, you get the best talent in the world
          </div>
        </div>
        <div className="px-12 text-center sm:px-0">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img src={trustgif} alt="trustgif"/>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            TRUSTWORTHY
          </h6>
          <div className="mb-2 text-gray-700">
            +15 years of experience finding the best talent around the globe
          </div>
        </div>
        <div className="px-12 text-center sm:px-0">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img src={geargif} alt="geargif"/>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            LOWER OPERATIONAL COSTS
          </h6>
          <div className="mb-2 text-gray-700">
            Unlock top talent at lower costs with our global recruitment solutions
          </div>
        </div>
      </div>
    </div>



    </div>

  );
};

export default AboutUs;