import "./Pricing.css"
import magnifyingglass from "../../assets/magnifyingglass.png"
import checklist from "../../assets/checklist.png"



export const Pricing = () => {
    return (
      <div className="py-24">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          {/*<h1 className="mb-4 py-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-rockethire-orange to-[#e9bfbf] ">
            Pricing
          </h1>  */}
            <h2 className="max-w-lg mb-6 font-sans text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-5xl md:mx-auto">
              <span className="relative inline-block">
                Our
                <span className="relative text-rockethire-orange"> Pricing </span>
              </span>{' '}
              
            </h2>   
              <p className="text-base text-gray-700 md:text-lg">
              We help <span className="font-bold text-black">startups</span> hire the right off-shore talent <span className="font-bold text-black">quickly and efficiently. </span>
              Instead of spending 2-3 weeks and over $3000 in internal time costs, we save you time by managing 
              your recruitment process — so you can focus on <span className="relative text-rockethire-orange font-bold"> what's most important </span> to your business.            
              </p>
          </div>
          <div className="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-xl lg:grid-cols-3 sm:mx-auto">
            <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
              <div className="mb-6">
                <div className="flex items-center justify-between pb-6 mb-6 border-b">
                  <div>
                    <p className="text-sm font-bold tracking-wider uppercase">
                      Consultation
                    </p>
                    <p className="text-5xl font-extrabold">Free</p>
                  </div>
                  <div className="flex items-center justify-center w-24 h-24 rounded-full">
                    <img src={checklist} alt="checklist"/>
                  </div>
                </div>
                <div>
                  <p className="mb-2 font-bold tracking-wide">Includes:</p>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">
                        Talent Sourcing Plan
                      </p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Candidate Pool Analysis</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Estimate hiring time</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Hiring Process Consultation</p>
                    </li>

                  </ul>
                </div>
              </div>
              <div>
                <a
                  href="https://calendly.com/rockethire-coffee/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
                >
                  Schedule Consultation Today
                </a>
                <p className="text-sm text-gray-600">
                  Kickstart your hiring process with a free expert consultation.
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
              <div className="mb-6">
                <div className="flex items-center justify-between pb-6 mb-6 border-b">
                  <div>
                    <p className="text-sm font-bold tracking-wider uppercase">
                     One-time Flat Fee
                    </p>
                    <p className="text-5xl font-extrabold">$1800</p>
                  </div>
                  <div className="flex items-center justify-center w-24 h-24 rounded-full">
                    <img src={magnifyingglass} alt="magnifying glass"/>
                  </div>
                </div>
                <div>
                  <p className="mb-2 font-bold tracking-wide">Includes:</p>
                  <ul className="space-y-2">
                    
                  <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">
                        Full recruitment strategy
                      </p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Full candidate screening</p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Tailored talent recommendations</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Salary negotiation assistance</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Hiring and Starting of candidate</p>
                    </li>
                                        <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">2-month replacement guarantee</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <a
                    href="https://calendly.com/rockethire-coffee/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
                  >
                    Let's talk!
                </a>
                <p className="text-sm text-gray-600">
                  Pay only after you hire, risk-free with payment flexibility.
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
              <div className="mb-6">
                <div className="flex items-center justify-between pb-6 mb-6 border-b">
                  <div>
                    <p className="text-sm font-bold tracking-wider uppercase">
                      HR Services Partner
                    </p>
                    <p className="text-5xl font-extrabold">Quote</p>
                  </div>
                  <div className="flex items-center justify-center w-24 h-24 rounded-full">
                    <img src={magnifyingglass} alt="magnifying glass"/>
                  </div>
                </div>
                <div>
                  <p className="mb-2 font-bold tracking-wide">Includes:</p>
                  <ul className="space-y-2">
                    
                  <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">
                        Full recruitment strategy
                      </p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Full candidate screening</p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Tailored talent recommendations</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Salary negotiation assistance</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Hiring and Starting of candidate</p>
                    </li>
                      <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">2-month replacement guarantee</p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Paperwork done by RocketHire</p>
                    </li>

                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg
                          className="w-4 h-4 text-rockethire-orange"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLidth="2"
                        >
                          <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                          />
                          <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                          />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">We are an extension of your HR Dept.</p>
                    </li>

                  </ul>
                </div>
              </div>
              <div>
                <a
                    href="https://calendly.com/rockethire-coffee/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-rockethire-orange rounded shadow-md hover:bg-[#f26101ad] focus:shadow-outline focus:outline-none"
                  >
                    Get quote!
                </a>
                <p className="text-sm text-gray-600">
                  This option is preferred for companies looking to scale with ease.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  };

  export default Pricing