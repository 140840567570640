import coffeecup from "../../assets/coffeecup.png"

const BookACall = () => {

  
    return (
      <div className="bg-[#ffffff]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="p-8 rounded-xl shadow-xl sm:p-16 bg-[#fff]">
          <div className="flex flex-col lg:flex-row">
            <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
              <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Let's share a <span className="text-rockethire-orange">coffee! ☕</span>
                <br className="hidden md:block" />
                {' '}
                <span className="text-xl font-normal inline-block text-black">
                  Have a conversation with us. Our mission as passionate recruiters
                  is to add <span className="text-rockethire-orange">value</span> to you and your company.
                </span>
              </h2>
            </div>
            <div className="lg:w-1/2">
              <a
                type=""
                href="https://calendly.com/rockethire-coffee/30min"
                aria-label=""
              >
                <button className=" bg-rockethire-orange text-white transition ease-in-out delay-10 bg-GRA hover:-translate-y-1 hover:scale-105 hover:bg-[#d8d9da] hover:text-black duration-120 p-5 px-24 rounded-2xl font-bold">
                  BOOK A CALL HERE
                </button>
                
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };


export default BookACall