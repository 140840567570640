import React from 'react';

const ManatalJobsApply = () => {
    return (
        <div className="relative h-[900px] bg-gray-100">
            <iframe 
                title="Career Page" 
                src="https://www.careers-page.com/rocket-hire-2" 
                className="absolute inset-0 w-full h-[900px] border-none"
            />
        </div>
    );
};

export default ManatalJobsApply;
