import { useState } from "react";


const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none text-center" // Updated this line
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium mx-auto">{title}</p> {/* Added mx-auto to center the title */}
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-500 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="bg-[#F0F1F2]">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div class="flex flex-col mb-16 sm:text-center">

          {/*<a href="/" class="mb-6 sm:mx-auto">
           
            <div class="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">

              TOP SVG OR IMAGE HERE

            </div>
          </a> */}

          <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
            <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span class="relative inline-block">

                <span class="relative">If you have any questions,</span>
              </span>{' '}
              we have the <span className="text-rockethire-orange">Answers</span>
            </h2>
            <p class="text-base text-gray-700 md:text-lg">
              If you have any other questions not listed here, you can always reach us at management@rockethire.ca 
              and we will answer right away.
            </p>
          </div>
        </div>
        <div class="space-y-4 text-center">
          <Item title="How does RocketHire ensure candidate quality? ">
          <div className="">
            <p className="py-2"><span className="font-bold text-rockethire-orange">Industry Expertise:</span> We not only specialize in your industry, but have worked in it for combined 15+years. We understand the specific skills and experience you need.</p>

            <p className="py-2"><span className="font-bold text-rockethire-orange">Rigorous Screening:</span> We go beyond resumes with assessments, portfolio reviews, and reference checks. We ensure the candidates want a job with your company and not just any job. </p>

            <p className="py-2"><span className="font-bold text-rockethire-orange">Clear Communication:</span> We work closely with you to understand your ideal candidate and keep everyone informed throughout the process. Everyone needs to measure up.</p>
          
            <p className="py-2"><span className="font-bold text-rockethire-orange">Tech-powered Sourcing:</span> We leverage advanced tools to find top talent in the local market</p>
          </div>
          </Item>
          <Item title="How much does it cost? ">
          <div className="">
            <p className="py-2">We have a flat fee of <span className="font-bold text-rockethire-orange">$1800</span> per hire!</p>

            <p className="py-2">We use a flat fee structure to keep your best interests at heart. This approach allows us to focus on finding you the top talent at a fair and reasonable salary and not push for the highest bidder, ensuring that you get the most value for your recruitment budget.  Choose us for a partnership that puts you first!<span className="font-bold text-rockethire-orange"></span> </p>

            
          </div>
          </Item>
          <Item title="Why is offshore talent beneficial for me?">
            <ul>
              <li className="py-2">
                <span className="font-bold text-rockethire-orange">Access to Offshore/Global Talent:</span> Offshore talent provides a diverse range of skills and expertise, enhancing project quality and efficiency.</li>
                <li className="py-2">
                <span className="font-bold text-rockethire-orange">Round-the-Clock Productivity:</span> With teams in different time zones, work continues 24/7, allowing tasks to progress continuously.               </li>
                <li className="py-2">
                <span className="font-bold text-rockethire-orange">Scalability:</span> Offshore teams adjust quickly to project needs, helping manage fluctuating workloads without the need for extensive hiring.
              </li>
              <li className="py-2">
                <span className="font-bold text-rockethire-orange">Lower Operational Cost:</span>  Offshore talent often comes at a lower cost compared to onshore resources. This cost advantage can significantly reduce the overall operational expenses for clients, enabling them to allocate resources 
                more efficiently and invest savings into other areas of their business.
              </li>
              <li className="py-2">
                <span className="font-bold text-rockethire-orange">Time-Zone Coverage:</span> Offshore resources are often less expensive, reducing overall operational costs and freeing up resources for other investments.
              </li>

            </ul>
          </Item>
          <Item title="How would I handle my payroll?">
            <ul>
              <li className="text-rockethire-orange font-bold">We do it for you:<span className="font-normal text-black"> For $150 per worker we can handle your monthly payroll needs.</span></li>
              <li className="text-rockethire-orange font-bold">International Deposit:<span className="font-normal text-black"> Deposit directly into the account of the contractor as payment for their service.</span></li>
              <li className="text-rockethire-orange font-bold">Payroll Provider:<span className="font-normal text-black"> We can recommend you our most trusted providers that will have you set up in minutes, who offer a minimal fee.</span></li>
            </ul>
          </Item>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FAQ